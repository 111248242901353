import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import { INavIcon, INavIconGroup, MegaNavContextType } from '../../store';
import styles from './index.module.scss';
import ancestorStyles from '../../index.module.scss';
import {
  Autocomplete,
  Button,
  IconEnum,
  SvgIcon,
} from '~/common/components/ui-elements';
import {
  capitalizeFirstLetter,
  colorBlack,
  colorPrimary,
  removeSpecialCharacters,
  toCamelCase,
} from '~/common/utils';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '~/common/components/ui-elements/floating-dialog';
import { MegaNavContext } from '../..';
import uuid from 'react-uuid';
import AsyncSelect from 'react-select/async';
import { Option } from '~/common/models';
import { setConfiguratorPostcode } from '~/common/services/dxp-postcode-service';
import { useSetRecoilState } from 'recoil';
import { UserLocationDealerState, UserLocationState } from '~/common/store';
import {
  SearchStore,
  useSearchStore,
} from '~/pages/search/components/search-provider';
import digitalData, {
  DigitalDataEvent,
} from '~/common/services/data-layer-service';
import { getDealerByPostcode } from '~/common/services/dealer-service';

type PostCodeOption = Option & {
  name: string;
};

const NavSearch = (props: { isMobile?: boolean }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [response, setResponse] = useState<string[] | null>(null);
  const [open, setOpen] = useState(false);
  const { getSuggestions } = useSearchStore();

  useEffect(() => setOpen((response?.length || 0) > 0), [response]);

  const handleSearch = async () => {
    if (searchQuery.trim() !== '') {
      setIsSearching(true);
      const searchUrl = `/search?query=${encodeURIComponent(searchQuery)}`;

      try {
        window.location.href = searchUrl;
      } catch (error) {
        console.error('Error searching', error);
      } finally {
        setIsSearching(false);
      }
    }
  };

  const handleClear = () => setSearchQuery('');

  const handleSuggestionClick = (suggestion: string) => {
    const searchUrl = `/search?query=${encodeURIComponent(suggestion)}`;
    window.location.href = searchUrl;
  };

  const fetchSearchSuggestions = async (query: string) => {
    if (query.trim() !== '') {
      const results = await getSuggestions(query);
      setResponse(results);
    }
  };

  const getSuggestion = (): string[] => {
    if (!response) return [];
    return response;
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setSearchQuery(inputValue);
    fetchSearchSuggestions(inputValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };

  return (
    <div
      className={cn(styles.SearchModalContainer, {
        [styles.SearchModalContainerMobile]: !!props.isMobile,
      })}
    >
      <div className={styles.SearchTextContainer}>
        <input
          type="text"
          value={searchQuery}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
          placeholder="What can we help you find?"
        />
        {searchQuery && (
          <div className={styles.IconClose} onClick={handleClear}>
            <SvgIcon type="close" size={1.5} color={`#${colorPrimary}`} />
          </div>
        )}
        <Button
          loading={isSearching}
          className={styles.ButtonSearch}
          onClick={handleSearch}
          buttonSize={'small'}
        >
          <SvgIcon type="search" size={1.8} color="#fff" />
        </Button>
      </div>
      {getSuggestion().length > 0 && (
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogContent
            className={cn(
              styles.DialogOverlayOverride,
              styles.DialogOverlayOverrideSuggestion,
              {
                [styles.DialogOverlayOverrideSuggestionMobile]: props.isMobile,
              }
            )}
            contentClassName={cn(
              styles.DialogOverlayContentOverride,
              styles.DialogOverlayContentOverrideSuggestion
            )}
            disableFocusOnDialog
            hideCloseButton
          >
            <div
              className={cn(styles.SearchSuggestions, {
                [styles.SearchSuggestionsMobile]: props.isMobile,
              })}
            >
              <ul>
                {getSuggestion().map((suggestion, index) => (
                  <li
                    key={index}
                    value={suggestion}
                    className={styles.Suggestion}
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

const FindADealer = ({
  setOpen,
}: {
  setOpen?: Dispatch<SetStateAction<boolean>>;
}) => {
  const autocompleteRef = React.useRef<AsyncSelect<Option> | null>(null);
  const [defaultOptions, setDefaultOptions] = useState<PostCodeOption[]>([]);
  const [suburb, setSuburb] = useState<PostCodeOption | undefined>(undefined);
  const setLocationState = useSetRecoilState(UserLocationState);
  const setDealer = useSetRecoilState(UserLocationDealerState);

  const asyncLoadOptions = (
    value: string,
    callback: (options: Option[]) => void
  ) => {
    if (value.length <= 2) {
      callback([]);
      setDefaultOptions([]);
      return;
    }

    fetch(`/api/location?search=${value}`)
      .then((res) => res.json())
      .then((res) => {
        const options = res.map((location) => ({
          label: `${location.name}, ${location.stateAbbreviationName} ${location.postcode}`,
          value: location.postcode,
          name: location.name,
        })) as PostCodeOption[];

        callback(options);
        setDefaultOptions(options);
      });
  };

  const handleOptionChange = async (value: Option | null) => {
    if (!!value) {
      const postcodeOption = value as PostCodeOption;
      setSuburb(postcodeOption);

      if (!!postcodeOption) {
        const location = {
          name: capitalizeFirstLetter(postcodeOption.name),
          fullName: postcodeOption.label,
          postcode: postcodeOption.value,
        };

        setConfiguratorPostcode(location.postcode).then(async () => {
          return setDealer(await getDealerByPostcode(location.postcode));
        });

        localStorage.setItem('suburb', JSON.stringify(location));
        localStorage.setItem('postalCode', postcodeOption.value);
        setLocationState(location);

        const _data: DigitalDataEvent = {
          event: '_formNavigate',
          form: {
            name: 'change postcode',
            stage: 'submitted',
            details: {
              vehicleSelected: [
                {
                  make: 'subaru',
                },
              ],
            },
          },
          user: {
            postcode: postcodeOption.value,
          },
        };
        if (window['ModelName'])
          _data.form.details.vehicleSelected[0]['model'] = window['ModelName'];

        digitalData.pushWithCommonData(_data, true);

        if (setOpen) setOpen(false);
      }
    }
  };

  return (
    <>
      <Autocomplete
        bordered
        value={suburb}
        ref={autocompleteRef}
        defaultOptions={defaultOptions}
        loadOptions={asyncLoadOptions}
        onChange={handleOptionChange}
        dropdownIndicator={() => (
          <SvgIcon
            type="pinLocation"
            size={1.25}
            color={`#${colorPrimary}`}
            className={styles.searchIcon}
            onClick={() => {
              autocompleteRef.current?.focus();
            }}
          />
        )}
        className={styles.PostcodeSelect}
        placeholder="Enter Postcode"
        name="postcode"
      />
      {setOpen && (
        <SvgIcon
          className={styles.PostcodeClose}
          type="close"
          size={1.5}
          color={`#${colorPrimary}`}
          onClick={() => setOpen(false)}
        />
      )}
    </>
  );
};

const IconNavItem = (props: INavIcon & { idx: number }) => {
  const { openIndex, setOpenIndex } = React.useContext(
    MegaNavContext
  ) as MegaNavContextType;
  const ref = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const isSearchPage = window.location.href.includes('/search');

  useEffect(() => {
    if (!open) {
      setOpenIndex(-1);
    }
  }, [open]);

  useEffect(() => {
    if (openIndex !== props.idx) {
      setOpen(false);
    } else {
      digitalData.pushWithCommonData(
        {
          event: '_formNavigate',
          form: {
            name: 'mega nav',
            stage: removeSpecialCharacters(props.text),
          },
        },
        true
      );
    }
  }, [openIndex]);

  const renderContent = () => {
    switch (toCamelCase(props.icon) as keyof typeof IconEnum) {
      case 'pinLocation':
        return <FindADealer setOpen={setOpen} />;
      case 'search':
        return (
          <SearchStore>
            <NavSearch />
          </SearchStore>
        );
    }
  };

  return (
    <div ref={ref} className={cn(styles.IconNavItem)}>
      {isSearchPage && props.icon === 'search' ? (
        <div
          className={cn(ancestorStyles.MainNav, styles.DialogTriggerOverride, {
            [ancestorStyles.NavActive]: openIndex === props.idx,
          })}
        >
          <SvgIcon
            type={toCamelCase(props.icon) as keyof typeof IconEnum}
            size={1}
            color={`#${colorPrimary}`}
            onClick={() => window.location.reload()}
          />
        </div>
      ) : (
        <Dialog open={open} onOpenChange={setOpen} root={ref.current}>
          <DialogTrigger
            className={cn(
              ancestorStyles.MainNav,
              styles.DialogTriggerOverride,
              {
                [ancestorStyles.NavActive]: openIndex === props.idx,
              }
            )}
            onClickComplete={(e) => setOpenIndex(e ? props.idx : -1)}
          >
            <SvgIcon
              type={toCamelCase(props.icon) as keyof typeof IconEnum}
              size={1}
              color={`#${colorPrimary}`}
            />
          </DialogTrigger>
          <DialogContent
            className={cn(styles.DialogOverlayOverride, {
              [styles.DialogOverlayOverrideSearch]: props.icon === 'search',
            })}
            contentClassName={cn(styles.DialogOverlayContentOverride)}
            disableFocusOnDialog
            hideCloseButton
          >
            {props.items && props.items.length > 0 ? (
              props.items?.map((i, idx) => (
                <div key={idx}>
                  <a href={i.url} target={i.target || '_self'}>
                    {i.name}
                  </a>
                </div>
              ))
            ) : (
              <>{renderContent()}</>
            )}
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

const IconNavItemMobile = (props: INavIcon & { idx: number }) => {
  const { openIndex, setOpenIndex, setOpenMobileDialogIdx } = React.useContext(
    MegaNavContext
  ) as MegaNavContextType;
  const ref = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const _id = uuid();
  const isSearchPage = window.location.href.includes('/search');

  useEffect(() => {
    if (!open) {
      setOpenIndex(-1);
    } else {
      setOpenMobileDialogIdx(-1);
    }
  }, [open]);

  useEffect(() => {
    if (openIndex !== props.idx) {
      setOpen(false);
    } else {
      digitalData.pushWithCommonData(
        {
          event: '_formNavigate',
          form: {
            name: 'mega nav',
            stage: removeSpecialCharacters(props.text),
          },
        },
        true
      );
    }
  }, [openIndex]);

  const renderContent = () => {
    switch (toCamelCase(props.icon) as keyof typeof IconEnum) {
      case 'pinLocation':
        return <FindADealer />;
      case 'search':
        return (
          <SearchStore>
            <NavSearch {...{ isMobile: true }} />
          </SearchStore>
        );
    }
  };

  return (
    <div ref={ref} id={_id} className={cn(styles.IconNavItemMobile)}>
      {isSearchPage && props.icon === 'search' ? (
        <div
          className={cn(ancestorStyles.MainNav, styles.NavMobile, {
            [ancestorStyles.NavActive]: openIndex === props.idx,
          })}
        >
          <SvgIcon
            type={toCamelCase(props.icon) as keyof typeof IconEnum}
            size={1.6}
            color={`#${colorBlack}`}
            onClick={() => window.location.reload()}
          />
        </div>
      ) : (
        <Dialog open={open} onOpenChange={setOpen} root={ref.current}>
          <DialogTrigger
            className={cn(
              ancestorStyles.MainNav,
              styles.NavMobile,
              styles.DialogTriggerOverride,
              {
                [ancestorStyles.NavActive]: openIndex === props.idx,
              }
            )}
            onClickComplete={(e) => setOpenIndex(e ? props.idx : -1)}
          >
            <SvgIcon
              type={toCamelCase(props.icon) as keyof typeof IconEnum}
              size={1.6}
              color={`#${colorBlack}`}
            />
          </DialogTrigger>
          <DialogContent
            className={cn(styles.DialogOverlayOverride, {
              [styles.DialogOverlayOverrideSearchMobile]:
                props.icon === 'search',
            })}
            contentClassName={styles.DialogOverlayContentOverride}
            disableFocusOnDialog
            hideCloseButton
          >
            {props.items && props.items.length > 0 ? (
              props.items?.map((i, idx) => (
                <div key={idx}>
                  <a href={i.url} target={i.target || '_self'}>
                    {i.name}
                  </a>
                </div>
              ))
            ) : (
              <>{renderContent()}</>
            )}
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

const IconListItemMobile = (props: INavIcon & { idx: number }) => {
  return (
    <div className={cn(styles.IconListItemMobile)}>
      <a href={props.link?.url} target={props.link?.target || '_self'}>
        {props.icon && (
          <SvgIcon
            type={toCamelCase(props.icon) as keyof typeof IconEnum}
            color={`#${colorBlack}`}
          />
        )}
        {props.text}
      </a>
    </div>
  );
};

export const IconGroupNav = memo(
  (
    props: INavIconGroup & { idx: number; displayOnMegaNavMobile?: boolean }
  ) => {
    const { isMobile } = React.useContext(MegaNavContext) as MegaNavContextType;

    return (
      <div
        key={props.idx}
        className={cn(ancestorStyles.MegaNavItemBlock, {
          [styles.IconGroupNav]: !isMobile,
          [styles.IconGroupNavMobile]: isMobile,
        })}
      >
        {props.items?.map((item, idx) => (
          <Fragment key={idx}>
            {!isMobile ? (
              <IconNavItem
                key={idx}
                {...{ ...item, idx: props.idx + idx / 100 }}
              />
            ) : !!props.displayOnMegaNavMobile ? (
              item.displayedOnMegaNavMobile ? (
                <IconNavItemMobile
                  key={idx}
                  {...{ ...item, idx: props.idx + idx / 100 }}
                />
              ) : (
                <></>
              )
            ) : !item.displayedOnMegaNavMobile ? (
              <IconListItemMobile
                key={idx}
                {...{ ...item, idx: props.idx + idx / 100 }}
              />
            ) : (
              <></>
            )}
          </Fragment>
        ))}
      </div>
    );
  }
);
